
export const POST_GET_IN_TOUCH_REQUEST = 'POST_GET_IN_TOUCH_REQUEST';
export const POST_GET_IN_TOUCH_SUCCESS = 'POST_GET_IN_TOUCH_SUCCESS';
export const POST_GET_IN_TOUCH_FAILURE = 'POST_GET_IN_TOUCH_FAILURE';

export const GET_GET_IN_TOUCH_REQUEST = 'GET_GET_IN_TOUCH_REQUEST';
export const GET_GET_IN_TOUCH_SUCCESS = 'GET_GET_IN_TOUCH_SUCCESS';
export const GET_GET_IN_TOUCH_FAILURE = 'GET_GET_IN_TOUCH_FAILURE';




export const POST_CONTACT_US_REQUEST = 'POST_CONTACT_US_REQUEST';
export const POST_CONTACT_US_SUCCESS = 'POST_CONTACT_US_SUCCESS';
export const POST_CONTACT_US_FAILURE = 'POST_CONTACT_US_FAILURE';

export const GET_CONTACT_US_REQUEST = 'GET_CONTACT_US_REQUEST';
export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_FAILURE = 'GET_CONTACT_US_FAILURE';




export const POST_CAMPUS_AMB_REG_REQUEST = 'POST_CAMPUS_AMB_REG_REQUEST';
export const POST_CAMPUS_AMB_REG_SUCCESS = 'POST_CAMPUS_AMB_REG_SUCCESS';
export const POST_CAMPUS_AMB_REG_FAILURE = 'POST_CAMPUS_AMB_REG_FAILURE';

export const GET_CAMPUS_AMB_REG_REQUEST = 'GET_CAMPUS_AMB_REG_REQUEST';
export const GET_CAMPUS_AMB_REG_SUCCESS = 'GET_CAMPUS_AMB_REG_SUCCESS';
export const GET_CAMPUS_AMB_REG_FAILURE = 'GET_CAMPUS_AMB_REG_FAILURE';




export const POST_WORKSHOP_REG_REQUEST='POST_WORKSHOP_REG_REQUEST'
export const POST_WORKSHOP_REG_SUCCESS='POST_WORKSHOP_REG_SUCCESS'
export const POST_WORKSHOP_REG_FAILURE='POST_WORKSHOP_REG_FAILURE'

export const GET_WORKSHOP_REG_REQUEST='GET_WORKSHOP_REG_REQUEST'
export const GET_WORKSHOP_REG_SUCCESS='GET_WORKSHOP_REG_SUCCESS'
export const GET_WORKSHOP_REG_FAILURE='GET_WORKSHOP_REG_FAILURE'






export const POST_WORKSHOP_REQUEST='POST_WORKSHOP_REQUEST'
export const POST_WORKSHOP_SUCCESS='POST_WORKSHOP_SUCCESS'
export const POST_WORKSHOP_FAILURE='POST_WORKSHOP_FAILURE'

export const GET_WORKSHOP_REQUEST='GET_WORKSHOP_REQUEST'
export const GET_WORKSHOP_SUCCESS='GET_WORKSHOP_SUCCESS'
export const GET_WORKSHOP_FAILURE='GET_WORKSHOP_FAILURE'

export const GET_WORKSHOPID_REQUEST='GET_WORKSHOPID_REQUEST'
export const GET_WORKSHOPID_SUCCESS='GET_WORKSHOPID_SUCCESS'
export const GET_WORKSHOPID_FAILURE='GET_WORKSHOPID_FAILURE'

export const PATCH_WORKSHOPID_REQUEST='PATCH_WORKSHOPID_REQUEST'
export const PATCH_WORKSHOPID_SUCCESS='PATCH_WORKSHOPID_SUCCESS'
export const PATCH_WORKSHOPID_FAILURE='PATCH_WORKSHOPID_FAILURE'

export const DELETE_WORKSHOPID_REQUEST='DELETE_WORKSHOPID_REQUEST'
export const DELETE_WORKSHOPID_SUCCESS='DELETE_WORKSHOPID_SUCCESS'
export const DELETE_WORKSHOPID_FAILURE='DELETE_WORKSHOPID_FAILURE'



export const POST_PRODUCT_REQUEST='POST_PRODUCT_REQUEST'
export const POST_PRODUCT_SUCCESS='POST_PRODUCT_SUCCESS'
export const POST_PRODUCT_FAILURE='POST_PRODUCT_FAILURE'

export const GET_PRODUCT_REQUEST='GET_PRODUCT_REQUEST'
export const GET_PRODUCT_SUCCESS='GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILURE='GET_PRODUCT_FAILURE'

export const GET_PRODUCTID_REQUEST='GET_PRODUCTID_REQUEST'
export const GET_PRODUCTID_SUCCESS='GET_PRODUCTID_SUCCESS'
export const GET_PRODUCTID_FAILURE='GET_PRODUCTID_FAILURE'

export const PATCH_PRODUCTID_REQUEST='PATCH_PRODUCTID_REQUEST'
export const PATCH_PRODUCTID_SUCCESS='PATCH_PRODUCTID_SUCCESS'
export const PATCH_PRODUCTID_FAILURE='PATCH_PRODUCTID_FAILURE'

export const DELETE_PRODUCTID_REQUEST='DELETE_PRODUCTID_REQUEST'
export const DELETE_PRODUCTID_SUCCESS='DELETE_PRODUCTID_SUCCESS'
export const DELETE_PRODUCTID_FAILURE='DELETE_PRODUCTID_FAILURE'